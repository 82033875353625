import request from '@/utils/request'
// const currentUrl = 'http://' + window.location.hostname
// const videoUrl = process.env.VUE_APP_VEDIO_URL || 'http://101.201.235.64' // 日后有需求可更改为环境变量模式
// const videoUrl = location.protocol + '//' + location.hostname
const videoUrl = location.origin

const api = {
  getVehicleForFastSearch: '/iot/vehicle/getVehicleForFastSearch',
  ffmpegStart: '/api/ffmpeg/start2',
  getUserCarList: '/api/vehicle/assigned',
  getCarList: '/api/vehicle',
  getLostGPSPoint: '/iot/vehicle/getLostGPSPoint',
  getVehicleTree: '/iot/vehicle/tree',
  // videoUrlList: [videoUrl + '/nms1', videoUrl + '/nms2', videoUrl + '/nms3'],
  videoUrlList: [videoUrl + '/nms'],
  getVehicleStatus: '/iot/vehicle/getVehicleStatus',
  getHistoricalTrack: '/iot/vehicle/getHistoricalTrack',
  getDashboards: '/iot/vehicle/dashboards',
  defaultConfigUpdate: '/iot/vehicle/defaultConfigUpdate',
  defaultConfig: '/iot/vehicle/defaultConfig',
  getDashboardsV2: '/iot/vehicle/dashboardsV2'
}

export default api

export function getVehicleTree(parameter) {
  return request({
    url: api.getVehicleTree,
    method: 'get',
    params: parameter
  })
}

export function getLostGPSPoint(parameter) {
  return request({
    url: api.getLostGPSPoint,
    method: 'get',
    params: parameter
  })
}

export function getUserCarList(parameter) {
  return request({
    url: api.getUserCarList,
    method: 'get',
    params: parameter
  })
}

export function getVehicleForFastSearch(parameter) {
  return request({
    url: api.getVehicleForFastSearch,
    method: 'get',
    params: parameter
  })
}

export function getVehicleStatus(parameter) {
  return request({
    url: api.getVehicleStatus,
    method: 'get',
    params: parameter
  })
}

export function getHistoricalTrack(parameter) {
  return request({
    url: api.getHistoricalTrack,
    method: 'get',
    params: parameter
  })
}

// 智驾车辆看板
export function getDashboards() {
  return request({
    url: api.getDashboards,
    method: 'get'
  })
}

// 智驾车辆dashboard默认设置修改
export function defaultConfigUpdate(data) {
  return request({
    url: api.defaultConfigUpdate,
    method: 'post',
    data: data
  })
}

// 获取智驾车辆dashboard搜索设置信息
export function getDefaultConfig() {
  return request({
    url: api.defaultConfig,
    method: 'get'
  })
}

// 智驾车辆看板V2
export function getDashboardsV2(parameter) {
  return request({
    url: api.getDashboardsV2,
    method: 'get',
    params: parameter
  })
}

// 获取智驾监控在线车辆列表
export function getOnlineVehicleList(query) {
  return request({
    url: '/iot/vehicle/vehicleList',
    method: 'get',
    params: query
  })
}

// 大屏智驾数据统计
export function getDashboardAutoVehicleQuery(query) {
  return request({
    url: '/iot/vehicle/autoVehicleQuery',
    method: 'get',
    params: query
  })
}

// 获取车辆所有信息数据
// 取代websocket type=all数据
export function getAllVehicleInfo(query) {
  return request({
    url: '/iot/vehicle/tree/all',
    method: 'get',
    params: query
  })
}

// 获取指定车辆信息数据
// 取代websocket type=oneVehicle数据
export function getOneVehicleInfo(query) {
  return request({
    url: '/iot/vehicle/tree/node',
    method: 'get',
    params: query
  })
}

// 查询指定时间范围内的历史摄像头，并返回主摄像头的历史视频记录
export function getVideoHistory(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/videoHistory',
    method: 'get',
    params: query
  })
}
